.mouse-tracker {
    position: fixed;
    top: 0;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    pointer-events: none;
}

.pointer {
    position: absolute;
    width: 20px;
    height: 20px;
    background-color: rgba(128, 128, 128, 0.5);
    border-radius: 50%;
    transition: all 0.1s ease;
}