.clock {
    height: min(80vw, 400px);
    width: min(80vw, 400px);
    background-color: #eee;
    border-radius: 50%;
    position: relative;
}

.clock-face {
    height: 100%;
    width: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
}

.tick {
    position: absolute;
    height: 100%;
    width: 2px;
}

.tick-mark {
    width: 100%;
    background-color: #000;
}

.tick-mark.long {
    height: 20px;
}

.tick-mark.short {
    height: 10px;
}

.needle {
    height: 100%;
    width: 100%;
    position: absolute;
    transform-origin: center;
    display: flex;
    justify-content: center;
}

.needle-body {
    height: 55%;
    display: flex;
    align-items: flex-end;
}

.needle-body.hour {
    height: 35%;
    width: 5px;
    background-color: #b60000;
    margin-top: 20%;
}

.needle-body.minute {
    height: 45%;
    width: 3px;
    background-color: #ac8700;
    margin-top: 10%;
}

.needle-body.second {
    height: 52%;
    width: 2px;
    background-color: #1a0084;
    margin-top: 3%;
}

.number-container {
    position: absolute;
    height: 100%;
    padding-top: 20px;
}

.number {
    font-size: 20px;
    color: #000;
}
