.expandable-text-container .expandable-text {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: var(--max-lines, 3);
  -webkit-box-orient: vertical;
}

.expandable-text-container .expandable-text.expanded {
  -webkit-line-clamp: unset;
}

.expandable-text-container button {
  background: none;
  border: none;
  color: blue;
  cursor: pointer;
  padding: 0;
  text-decoration: underline;
}
