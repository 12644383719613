.infinite-scroll-container {
  max-width: 600px;
  margin: auto;
  text-align: center;
  font-family: Arial, sans-serif;
}

.post-list {
  list-style: none;
  padding: 0;
}

.post-item {
  background: #f8f8f8;
  margin: 10px 0;
  padding: 15px;
  border-radius: 5px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}

.infinite-scroll-loading {
  font-size: 18px;
  font-weight: bold;
  margin-top: 20px;
  color: #555;
}
