.task-container {
    height: 25px;
    background-color: grey;
    border-radius: 5px;
    position: relative;
    overflow: hidden;
    margin: 5px;
}

.task-progress {
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: rgb(0, 123, 37);
    transition: transform 0.1s linear;
    transform: translateX(-100%);
}

.task-container span {
    color: white;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

.task-progress.completed {
    background-color: rgb(0, 70, 123);
}