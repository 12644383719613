.countdown-timer {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: Arial, sans-serif;
}

.countdown-timer input {
  margin-bottom: 10px;
  padding: 5px;
  font-size: 16px;
}

.countdown-timer .time-display {
  font-size: 24px;
  margin-bottom: 10px;
}

.countdown-timer button {
  margin: 5px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}
