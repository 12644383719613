.counter {
  text-align: center;
  margin: 20px;
}

.counter h1 {
  font-size: 2em;
  margin-bottom: 20px;
}

.counter button {
  margin: 5px;
  padding: 10px 20px;
  font-size: 1em;
  cursor: pointer;
}

.counter button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}
