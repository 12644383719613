.chat-app-container {
    display: flex;
    flex-direction: column;
    height: 60vh;
    width: 100%;
    border: 1px solid #ccc;
}

.chat-app-message-list {
    flex: 1;
    overflow-y: auto;
    padding: 10px;
    border-bottom: 1px solid #ccc;
    display: flex;
    flex-direction: column;
}

.chat-app-message {
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 5px;
    max-width: 60%;
    color: black;
}

.chat-app-message.sent {
    background-color: #d1e7dd;
    align-self: flex-end;
}

.chat-app-message.received {
    background-color: #f8d7da;
    align-self: flex-start;
}

.chat-app-input-area {
    display: flex;
    padding: 10px;
}

.chat-app-input-area input {
    flex: 1;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.chat-app-input-area button {
    margin-left: 10px;
    padding: 10px;
    border: none;
    border-radius: 5px;
    background-color: #007bff;
    color: white;
    cursor: pointer;
}

.chat-app-input-area button.disabled {
    background-color: #888888;
}

