.resizable-panels {
  display: flex;
  width: 100%;
  height: 50vh;
}

.resizable-panels .panel {
  flex-grow: 1;
  overflow: auto;
}

.resizable-panels .left {
  background-color: #5a9d68;
}

.resizable-panels .right {
  background-color: #685fa9;
}

.resizable-panels .drag-handle {
  width: 20px;
  cursor: ew-resize;
  background-color: #ccc;
}