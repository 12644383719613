
.accordion {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  color: #000;
}

.accordion-section {
  border: 1px solid #ccc;
  margin-bottom: 10px;
}

.accordion-title {
  width: 100%;
  padding: 10px;
  background-color: #f7f7f7;
  border: none;
  text-align: left;
  cursor: pointer;
  font-size: 16px;
}

.accordion-content {
  padding: 10px;
  background-color: #fff;
  border-top: 1px solid #ccc;
}
