.paginated-table-container {
  max-width: 600px;
  margin: auto;
  text-align: center;
  font-family: Arial, sans-serif;
}

.paginated-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.paginated-table th, .paginated-table td {
  border: 1px solid #ddd;
  padding: 10px;
}

.paginated-table th {
  background-color: #f4f4f4;
}

.paginated-table-pagination {
  margin-top: 20px;
}

.paginated-table-button {
  margin: 5px;
  padding: 8px 12px;
  border: none;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  border-radius: 5px;
}

.paginated-table-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.paginated-table-loading {
  font-size: 18px;
  font-weight: bold;
  color: #555;
}
