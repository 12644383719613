.fab {
    position: fixed;
    bottom: 16px;
    right: 16px;
    width: 56px;
    height: 56px;
    border-radius: 50%;
    background-color: #6200ea;
    color: white;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
    cursor: pointer;
    transition: background-color 0.3s;
}

.fab:hover {
    background-color: #3700b3;
}

.fab .plus {
    font-size: 3rem;
}