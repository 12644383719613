
.digital-clock {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  font-family: 'Courier New', Courier, monospace;
  background-color: #000;
  color: #0f0;
}

.time {
  font-size: 4rem;
}
