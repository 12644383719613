.stopwatch {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: Arial, sans-serif;
}

.stopwatch .time {
    font-size: 2rem;
    margin-bottom: 1rem;
}

.stopwatch button {
    margin: 0.5rem;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    cursor: pointer;
}
