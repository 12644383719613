.todo-app {
  font-family: Arial, sans-serif;
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.todo-app h1 {
  text-align: center;
}

.todo-input {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.todo-input input {
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.todo-input button {
  padding: 10px 20px;
  margin-left: 10px;
  border: none;
  background-color: #28a745;
  color: white;
  border-radius: 4px;
  cursor: pointer;
}

.todo-input button:hover {
  background-color: #218838;
}

.todo-list {
  list-style: none;
  padding: 0;
}

.todo-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #ccc;
}

.todo-item:last-child {
  border-bottom: none;
}

.todo-text {
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: space-between;
}

.todo-text span {
  margin-right: 10px;
  flex: 1;
}

.todo-text button {
  margin-left: 5px;
  padding: 5px 10px;
  border: none;
  background-color: #007bff;
  color: white;
  border-radius: 4px;
  cursor: pointer;
}

.todo-text button:hover {
  background-color: #0056b3;
}

.edit-todo {
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: space-between;
}

.edit-todo input {
  flex: 1;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.edit-todo button {
  padding: 5px 10px;
  margin-left: 10px;
  border: none;
  background-color: #ffc107;
  color: white;
  border-radius: 4px;
  cursor: pointer;
}

.edit-todo button:hover {
  background-color: #e0a800;
}
