.po_items_ho {
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
}

.po_item {
  transition: all 0.3s ease;
  cursor: pointer;
  position: relative;
}

.po_item .po_item_img {
  background-color: transparent;
  transform: scale(1);
  transition: all 0.3s ease;
  box-shadow: 0px 0px 2px 0px #2b87a8, 0 0 10px 5px transparent;
}

.po_item:hover .po_item_img {
  transform: translate(5px, -5px);
  box-shadow: -5px 5px 16px 0px #2b87a8, 0 0 10px 5px transparent;
}

.po_item .description {
  font-size: 20px;
  position: absolute;
  width: 100%;
  transition: all 0.3s ease;
  text-align: center;
  text-decoration: underline;
  top: 0px;
}

.po_item:hover .description {
  top: -35px;
}

@media (min-width: 991.98px) {
  .po_item {
    width: 45%;
  }
}
